/* eslint-disable */
import React, { useState } from 'react'

import { Layout } from '@leshen/gatsby-theme-contentful'
import {
  Breadcrumbs,
  Typography,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Form } from '@leshen/ui'
import { graphql } from 'gatsby'
const { EmilyClient } = require('@clearlink/emily')

const SpanishDoNotSell = () => {
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false)
  const { requestId, sessionPromoCode } = useMapi()
  let promoCode = sessionPromoCode === 'SAMPLEPROMO' ? 147165 : sessionPromoCode

  const layoutProps = {
    data: {
      contentfulPage: {
        seo: {
          title: '',
          description: '',
          canonical: 'https://clearlinkinsurance.com/do-not-sell',
          robots: 'follow,index',
        },
        path: '/do-not-sell',
        promoCode: '147165',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/do-not-sell',
          siteName: 'clearlinkinsurance.com',
          alternateName: 'clearlinkinsurance.com',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'clearlinkinsurance.com | Do Not Sell',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="wrapper">
          <VariableContent
            backgroundColor=""
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h1">
                  Derechos de privacidad de California
                </Typography>
                <Typography variant="body">
                  La Ley de Privacidad del Consumidor de California (CCPA)
                  otorga a los residentes de California ciertos derechos con
                  respecto a la información personal proporcionada a las
                  empresas. Uno de los derechos otorgados es solicitar a una
                  empresa que deje de vender tu información personal. Junto con
                  la información contenida en nuestra política de privacidad,
                  queremos brindarte un medio para indicar específicamente tus
                  preferencias con respecto a la venta de tu información. Para
                  enviar una solicitud para que tus datos no se vendan, completa
                  el formulario en esta página.
                </Typography>
              </>
            }
          />
          <VariableContent
            backgroundColor="light"
            alignMainContent="center"
            mainContent={
              <Form
                customSubmissionMessage={
                  <Typography variant="body">Gracias por tu envío</Typography>
                }
                formId={null}
                formSource="eloqua"
                submitButtonText="Verificar"
                submitButtonColor="primary"
                submitButtonOutlined={false}
                submitButtonGradient={false}
                inlineButton={false}
                formName={null}
                onSubmit={async ({
                  values,
                  setShowSubmissionMessage,
                  setShowSubmissionErrorMessage,
                }) => {
                  const mergedValues = {
                    ...values,
                    brand: 'ATT',
                    elqFormName: 'do-not-sell',
                    origin: 'https://www.attsavings.com',
                    domain: 'https://www.attsavings.com',
                    promo_code: promoCode,
                    request_id: requestId,
                    source: 'do-not-sell',
                  }

                  try {
                    const emilyClient = new EmilyClient(
                      'https://leads.clear.link'
                    )

                    const response = await emilyClient.submit(mergedValues)

                    if (response.status === 200) {
                      setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                    } else {
                      setShowSubmissionMessage(false)
                    }
                    setShowSubmissionMessage(true)
                  } catch (error) {
                    // If an error occurs:
                    console.error('Submission error:', error)
                    setShowSubmissionMessage(false)
                    setShowSubmissionErrorMessage(true)
                  }
                }}
                emilyTCPAUpdate={() => {}}
                steps={null}
                showSubmissionMessage={showSubmissionMessage}
                fields={[
                  {
                    name: 'first_name',
                    type: 'text',
                    label: 'Nombre',
                    required: true,
                  },
                  {
                    name: 'last_name',
                    type: 'text',
                    label: 'Appellido',
                    required: true,
                  },
                  {
                    name: 'email_address',
                    type: 'email',
                    label: 'Correo',
                    required: true,
                  },
                ]}
                isMultiStep={false}
                heading={null}
                subheading={null}
                tcpaCheckbox={false}
                formDisclaimerPosition="Below Submit Button"
                disclaimer={null}
                initialStepValues={{}}
                smartyStreetsWebsiteKey={null}
                addressQualificationCode={null}
                spanish={false}
                requestId={null}
              />
            }
          />
        </div>
        <div>
          <Breadcrumbs path="/es/no-vendas-mi-informacion" />
        </div>
      </>
    ),
    disableBreadcrumbs: true,
  }

  return <Layout {...layoutProps} />
}

export default SpanishDoNotSell

export const query = graphql`
  query SpanishDoNotSellQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
